import styled from 'styled-components';

export const EmptyCheckBox = styled('span')(() => ({
  borderRadius: 5,
  width: 16,
  height: 16,
  boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
  backgroundColor: 'white',
  'input:hover ~ &': {
    backgroundColor: 'transparent',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'grey',
  },
}));
