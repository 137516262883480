import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import React, { MouseEvent, useState, EventHandler, SyntheticEvent } from 'react';
import { CheckedCheckbox, EmptyCheckBox } from '../../atoms/checkbox';

type MultiSelectWithOtherProps = {
  title: string;
  options: string[];
  width: string;
  height?: string;
  onChange: (selectedValues: string[]) => void;
  size?: 'small' | 'medium';
  register?: any;
  defaultValues?: string[];
  emptyOtherText?: string;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  style?: SxProps;
  limitTags?: number;
  disabled?: boolean;
  textFieldDynamicBorder?: boolean;
};

const OTHER = 'Other';

const preventClosingDropDown = (evt: MouseEvent<HTMLElement>) => {
  evt.preventDefault();
};

const MultiSelectWithOther: React.FunctionComponent<MultiSelectWithOtherProps> = ({
  title,
  options,
  width,
  height,
  onChange,
  size,
  register,
  defaultValues,
  emptyOtherText,
  variant,
  style,
  limitTags,
  disabled,
  textFieldDynamicBorder,
}) => {
  const [otherText, setOtherText] = useState<string>();
  const [inputText, setInputText] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<string[]>(defaultValues || []);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOtherChange = (
    action: EventHandler<SyntheticEvent<Element>>,
    evt: SyntheticEvent<Element, Event>,
  ) => {
    if (otherText) {
      setOtherText(undefined);
      setInputText(undefined);
      action(evt);
    } else if (!isEmpty(inputText)) {
      action(evt);
      setOtherText(inputText);
      setInputText(undefined);
    }
  };

  return (
    <Autocomplete
      multiple
      onChange={(_, values: string[]) => {
        let newSelectedValues: string[];
        if (isEmpty(values)) {
          newSelectedValues = [];
        } else if (otherText && values.includes(OTHER) && values.includes(otherText)) {
          newSelectedValues = values.filter((val) => val !== OTHER && val !== otherText);
        } else {
          newSelectedValues = values.includes(OTHER)
            ? values.map((selectedTitle) => (selectedTitle === OTHER ? inputText! : selectedTitle))
            : values;
        }
        if (isEmpty(newSelectedValues) || (otherText && !values.includes(otherText))) {
          setOtherText(undefined);
          setInputText(undefined);
        }
        setSelectedItems(newSelectedValues);
        onChange(newSelectedValues);
      }}
      filterOptions={(selectedOptions: string[], state) =>
        selectedOptions.filter(
          (value) =>
            value.toLowerCase().includes(state.inputValue.toLowerCase()) || value === OTHER,
        )
      }
      value={selectedItems}
      size={size || 'small'}
      limitTags={limitTags || 1}
      onBlur={() => {
        setInputText(undefined);
      }}
      onKeyDown={(evt) => {
        if (inputText && !otherText && evt.key === 'Enter') {
          evt.preventDefault();
          setSelectedItems([...selectedItems, inputText]);
          setOtherText(inputText);
          setInputText(undefined);
        }
      }}
      disableCloseOnSelect
      id="multi-select-with-other"
      options={[...options, OTHER]}
      renderInput={(params) => (
        <TextField
          fontFamily="var(--font, inherit)"
          {...register?.('Titles', {})}
          {...params}
          label={title}
          placeholder={!isEmpty(selectedItems) ? '' : title}
          onChange={(evt) => setInputText(evt.target.value)}
          variant={variant}
        />
      )}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      renderOption={(props, option: string, state) => {
        const currentOtherText = otherText || inputText || '';
        const addOrRemoveText = otherText ? 'Remove' : 'Add';
        if (option === OTHER) {
          return (
            <li
              {...props}
              onClick={(evt) => evt?.preventDefault()}
              onKeyDown={(evt) => evt?.preventDefault()}
              style={{ fontFamily: 'var(--font, inherit)' }}
            >
              {currentOtherText ? (
                <>
                  <span style={{ flex: 1 }}>
                    {addOrRemoveText} "{currentOtherText}" as title?
                  </span>
                  <li
                    {...props}
                    onClick={(evt) => handleOtherChange(props?.onClick || (() => {}), evt)}
                    onKeyDown={(evt) => evt?.preventDefault()}
                    style={{ justifyContent: 'flex-end', flex: 1 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      sx={{
                        margin: '0 10px',
                        textTransform: 'none',
                      }}
                    >
                      {addOrRemoveText}
                    </Button>
                  </li>
                </>
              ) : (
                <>{emptyOtherText || "Didn't find your title? Start typing to create a new one"}</>
              )}
            </li>
          );
        }
        return (
          <li {...props}>
            <FormControlLabel
              onClick={preventClosingDropDown}
              label={<Typography fontFamily="var(--font, inherit)">{option}</Typography>}
              control={
                <Checkbox
                  checked={state.selected}
                  checkedIcon={<CheckedCheckbox />}
                  icon={<EmptyCheckBox />}
                />
              }
            />
          </li>
        );
      }}
      sx={{
        width,
        '& .MuiInputBase-root': {
          height: (isOpen && selectedItems.length > 1) || !height ? 'auto' : height,
        },
        '& .MuiAutocomplete-endAdornment': { top: 'auto' },
        '& .MuiOutlinedInput-notchedOutline': textFieldDynamicBorder
          ? {}
          : { border: '1px solid black' },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border: `${textFieldDynamicBorder ? '2px' : '1px'} solid black`,
          },
        },
        ...style,
      }}
      disabled={disabled}
    />
  );
};

export default MultiSelectWithOther;
