import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { PhoneNumberType } from '../types';

export function formatPhoneToLocal(phoneNumber: PhoneNumberType): string {
  const cleaned = `${phoneNumber.number}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const [, areaCode, prefix, lineNumber] = match;

    return `(${areaCode}) ${prefix}-${lineNumber}`;
  }

  return phoneNumber.number;
}

export function stringToPhoneNumber(number: string): PhoneNumberType | undefined {
  const phoneNumber = parsePhoneNumberFromString(number, 'US');
  if (!phoneNumber) return undefined;

  return {
    number: phoneNumber.nationalNumber as string,
    countryDialingCode: `+${phoneNumber.countryCallingCode}`,
  };
}

export function valuePhoneNumber(number: string): string | undefined {
  const phoneNumber = parsePhoneNumberFromString(number, 'US');

  return phoneNumber?.formatNational();
}
